import { createApp } from 'vue'
import { createHead } from '@vueuse/head';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
const head = createHead();

const app = createApp(App)

app.use(store)
   .use(router)
   .use(head)
   .mount('#app')