<template>
  <div class="about">
    <div class="section section-1" :style="{ backgroundImage: 'url(' + section1Background + ')' }">
      <div class="content">
        <div class="left-content">
          <div class="hero-info pt-sans-regular">
            <h1 class="hero-heading mt-5">About</h1>
            <div class="hero-info-text-box">
              <p class="hero-paragraph">
                Francesca Fiore is a womenswear brand that creates unique pieces.
                <br><br>With a mission to use some of our existing resources, deadstock fabrics and accessory details are being upcycled, becoming part of the creation process.
                <br><br>Fashion Aesthetic: edgy-chic, high quality with a twist of punk, unique pieces come to life.
                <br><br>Benvenuti to my open portfolio and start-up Brand Francesca Fiore!
              </p>
            </div>
          </div>
        </div>
        <div class="right-content">
        </div>
        <div class="arrow-container">
          <i class="fas fa-chevron-down"></i> <!-- Font Awesome down arrow -->
        </div>
      </div>
    </div>
    <div class="section section-2" :style="{ backgroundImage: 'url(' + section2Background + ')' }">
      <div class="content">
        <div class="left-content">
        </div>
        <div class="right-content">
          <div class="hero-info pt-sans-regular">
            <h1 class="hero-heading mt-5">How it came to life</h1>
            <div class="hero-info-text-box">
              <p class="hero-paragraph">
                Benvenuti to my open portfolio and start-up Brand Francesca Fiore!
                <br><br>Since I was a teenager I always bought fashion magazines to read on the train and drew inspiration from couture runway shows.
                <br><br>I am so thankful that I pursued a degree in Fashion Design and built a strong network of people in various professions.
                <br><br>With a great team, amazing projects come to life.
                <br><br>During the process of choosing the topic, sketching, writing, organizing the Photoshooting location, and finding the models <b>a story is being told.</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutView',
  data () {
    return {
      // Dynamically set the background image URLs for each section
      section1Background: require('@/assets/light-page.jpg'), // path to the first image
      section2Background: require('@/assets/website-look-3/look3_3.jpeg'), // path to the second image
    };
  }
}
</script>

<style scoped>
.about {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: #d2b48c;
  /* Fallback background color */
}

.section {
  height: 100vh;
  /* Make each section 100% of the viewport height */
  background-size: cover;
  background-position: center;
  position: relative;
}

.section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  /* Dark overlay */
  z-index: 1;
}

.arrow-container {
  position: absolute;
  bottom: 20px;
  /* Position the arrow 20px from the bottom */
  left: 50%;
  /* Center horizontally */
  transform: translateX(-50%);
  /* Adjust for exact centering */
  font-size: 2rem;
  /* Adjust the size of the arrow */
  color: white;
  /* Arrow color */
  cursor: pointer;
  /* Makes it look clickable */
  animation: bounce 1.5s infinite;
  /* Optional: Add a bounce animation */
}

@keyframes bounce {

  0%,
  100% {
    transform: translateX(-50%) translateY(0);
  }

  50% {
    transform: translateX(-50%) translateY(-10px);
  }
}

.content {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.left-content {
  flex: 1;
  max-width: 600px;
}

.right-content {
  width: 40%;
  padding-right: 2rem;
}

.hero-heading {
  font-size: 2rem;
  color: #faebd7;
}

.hero-paragraph {
  font-size: 1.2rem;
  color: #faebd7;
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    flex-wrap: nowrap;
  }

  .left-content {
    margin: 0.2rem;
    width: 100%;
    margin-top: 4rem;
    position: absolute;
  }

  .right-content {
    width: 100%;
    padding-right: 0;
    margin-top: 4rem;
  }

  .hero-paragraph {
    margin: 1rem;
  }
}
</style>
