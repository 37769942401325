import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Francesca Fiore - Unique Womenswear',
      description: 'Welcome to Francesca Fiore – a womenswear brand creating edgy-chic, high-quality designs with a punk twist. Explore my open portfolio, where fashion meets sustainability through upcycled fabrics and unique storytelling.'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      title: 'About Francesca Fiore - Fashion Designer',
      description: 'Benvenuti to Francesca Fiore! A passionate fashion designer inspired by couture runway shows. Discover my journey in womenswear, from sketching ideas to bringing unique, high-quality designs to life with upcycled materials.'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue'),
    meta: {
      title: 'Contact Francesca Fiore - Get in Touch',
      description: 'Reach out to Francesca Fiore for collaborations, fashion inquiries, or general questions. Let’s create something unique together!'
    }
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import(/* webpackChunkName: "portfolio" */ '../views/PortfolioView.vue'),
    meta: {
      title: 'Portfolio - Francesca Fiore',
      description: 'Explore Francesca Fiore’s portfolio of unique, high-fashion womenswear pieces. See how creativity, sustainability, and couture-inspired design come together.'
    }
  },
  {
    path: '/styling',
    name: 'styling',
    component: () => import(/* webpackChunkName: "styling" */ '../views/StylingView.vue'),
    meta: {
      title: 'Styling - Francesca Fiore',
      description: 'Discover Francesca Fiore’s styling.'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
